import React, { ReactNode } from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';

type Props = {
  children: ReactNode;
  type: 'primary' | 'secondary';
  className?: string;
  onClick?: () => void;
  isDisabled?: boolean;
};

export const Button = ({
  children,
  type,
  className,
  onClick,
  isDisabled,
}: Props) => {
  const getButtonBackgroundColor = () => {
    if (isDisabled) {
      return process.env.REACT_APP_DISABLED_BUTTON_COLOR;
    }

    if (type === 'primary') {
      return process.env.REACT_APP_MAIN_COLOR;
    }

    if (type === 'secondary') {
      return process.env.REACT_APP_WHITE_COLOR;
    }
  };

  const getButtonColor = () => {
    if (type === 'primary') {
      return process.env.REACT_APP_WHITE_COLOR;
    }

    if (type === 'secondary') {
      return process.env.REACT_APP_MAIN_COLOR;
    }
  };

  return (
    <button
      className={cx(styles.buttonContainer, className)}
      style={{
        backgroundColor: getButtonBackgroundColor(),
        color: getButtonColor(),
      }}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default Button;
