import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Label.module.scss';

type Props = {
  children?: ReactNode;
  variant: 'success';
  className?: string;
};

export const Label = ({ className, variant, children }: Props) => (
  <span
    className={cx(styles.label, className, {
      [styles.success]: variant === 'success',
    })}
  >
    {children}
  </span>
);

export default Label;
