import React, { useState } from 'react';
import styles from './EmailInput.module.scss';
import Button from '../Button/Button';
import emailIcon from '../../assets/footer_email.svg';
import { ReactSVG } from 'react-svg';
import emailjs from 'emailjs-com';
import Label from '../Label/Label';

export const EmailInput = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const sendEmail = () => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID ?? '');
    setIsSuccess(false);
    setIsLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID ?? '',
        process.env.REACT_APP_EMAILJS_SUBSCRIPTION_TEMPLATE_ID ?? '',
        { email },
      )
      .then(() => {
        setIsSuccess(true);
        setEmail('');
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className={styles.emailInputContainer}
        style={{ backgroundColor: process.env.REACT_APP_WHITE_COLOR }}
      >
        <div className={styles.inputContainer}>
          <ReactSVG
            src={emailIcon}
            style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
            className={styles.icon}
          />
          <input
            style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
            placeholder={process.env.REACT_APP_EMAIL_INPUT_PLACEHOLDER}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <Button
          type="primary"
          className={styles.button}
          onClick={() => sendEmail()}
          isDisabled={isLoading}
        >
          {process.env.REACT_APP_EMAIL_BUTTON}
        </Button>
      </div>
      {isSuccess && <Label variant="success">Sėkmingai išsiųsta!</Label>}
    </>
  );
};

export default EmailInput;
