export const routes = {
  homepage: '',
  courses: '/kursai',
  business: '/verslui',
  finance: '/finansavimas',
  privacyPolicy: '/privatumo-politika',
  contacts: '/kontaktai',
  registration: '/registracija',
  consultation: '/konsultacija',
  article: process.env.REACT_APP_ARTICLE_URL,
};
