import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import { ReactSVG } from 'react-svg';
import scrollbarWave from '../../assets/waves/scrollbar-wave.svg';
import Button from '../Button/Button';
import { routes } from '../../config/Router/routes';

export type Props = {
  children: ReactNode;
};

const MOBILE_BREAK_POINT = 900;

export type NavigationItem = {
  label: string;
  to: string;
  isExternal?: boolean;
};

const Layout = ({ children }: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const routeLocation = useLocation();

  const { width } = useWindowSize();

  const NAVIGATION_ITEMS = [
    {
      label: process.env.REACT_APP_NAVIGATION_1_NAME ?? '',
      to: routes.courses,
    },
    {
      label: process.env.REACT_APP_NAVIGATION_2_NAME ?? '',
      to: process.env.REACT_APP_NAVIGATION_2_URL ?? '',
      isExternal: true,
    },
    {
      label: process.env.REACT_APP_NAVIGATION_3_NAME ?? '',
      to: routes.business,
    },
    {
      label: process.env.REACT_APP_NAVIGATION_4_NAME ?? '',
      to: routes.contacts,
    },
  ];

  useEffect(() => {
    const rootElement = document.getElementById('root');
    rootElement?.scroll({ top: 0 });

    setIsMobileMenuOpen(false);

    // @ts-ignore
    window?.dataLayer?.push({
      event: 'pageview',
    });
  }, [routeLocation.key]);

  useEffect(() => {
    const rootElement = document.getElementById('root');

    if (!rootElement) {
      return;
    }

    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      rootElement.style.overflow = 'hidden';
    } else {
      rootElement.style.overflow = 'auto';
    }

    return () => {
      rootElement.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <Navigation
        onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
        isMobileMenuOpen={isMobileMenuOpen}
        navigationItems={NAVIGATION_ITEMS.filter(
          (navigationItem) => !!navigationItem.label,
        )}
      />
      {children}
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div
          className={styles.mobileDrawer}
          style={{ backgroundColor: process.env.REACT_APP_WHITE_COLOR }}
        >
          <div
            className={styles.navigationItems}
            style={{ backgroundColor: process.env.REACT_APP_THIRD_COLOR }}
          >
            {NAVIGATION_ITEMS.filter(
              (navigationItem) => !!navigationItem.label,
            ).map((navigationItem) => {
              if (navigationItem.isExternal) {
                return (
                  <a
                    key={navigationItem.label}
                    href={navigationItem.to}
                    className={styles.navigationItem}
                    style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    {navigationItem.label}
                  </a>
                );
              }

              return (
                <NavLink
                  key={navigationItem.label}
                  to={navigationItem.to}
                  className={styles.navigationItem}
                  style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
                >
                  {navigationItem.label}
                </NavLink>
              );
            })}
          </div>
          <ReactSVG
            className={styles.waveIcon}
            src={scrollbarWave}
            style={{
              color: process.env.REACT_APP_SECONDARY_COLOR,
            }}
          />
          <Button
            type="primary"
            className={styles.buttonContainer}
            onClick={() => navigate(routes.registration)}
          >
            {process.env.REACT_APP_NAVIGATION_BUTTON}
          </Button>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Layout;
