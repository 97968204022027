import React from 'react';
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import facebookIcon from '../../assets/facebook_icon.svg';
import linkedinIcon from '../../assets/linkedin_icon.svg';
import { ReactSVG } from 'react-svg';
import EmailInput from '../EmailInput/EmailInput';
import { routes } from '../../config/Router/routes';

export const Footer = () => {
  const LINKS = [
    {
      name: process.env.REACT_APP_FOOTER_ITEM_1_NAME,
      link: routes.courses,
    },
    {
      name: process.env.REACT_APP_FOOTER_ITEM_2_NAME,
      link: process.env.REACT_APP_FOOTER_ITEM_2_LINK,
      isExternal: true,
    },
    {
      name: process.env.REACT_APP_FOOTER_ITEM_3_NAME,
      link: routes.finance,
    },
    {
      name: process.env.REACT_APP_FOOTER_ITEM_4_NAME,
      link: routes.business,
    },
    {
      name: process.env.REACT_APP_FOOTER_ITEM_5_NAME,
      link: process.env.REACT_APP_FOOTER_ITEM_5_LINK,
      isExternal: true,
    },
    {
      name: process.env.REACT_APP_FOOTER_ITEM_6_NAME,
      link: routes.contacts,
    },
  ];

  return (
    <div className={styles.footer}>
      <div
        className={styles.footerContainer}
        style={{ backgroundColor: process.env.REACT_APP_SECONDARY_COLOR }}
      >
        <div className={styles.footerContent}>
          <div className={styles.footerInfo}>
            <div className={styles.siteName}>
              <Link
                to={''}
                style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
              >
                {process.env.REACT_APP_FOOTER_SITE_NAME}
              </Link>
            </div>
            <div
              className={styles.infoLine}
              style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
            >
              {process.env.REACT_APP_FOOTER_ADDRESS}
            </div>
            <div
              className={styles.infoLine}
              style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
            >
              {process.env.REACT_APP_FOOTER_COMPANY}
            </div>
            <div className={styles.socialIcons}>
              <a
                href={process.env.REACT_APP_FACEBOOK_URL}
                style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
                rel="nofollow noreferrer"
                target="_blank"
              >
                <ReactSVG src={facebookIcon} />
              </a>
              <a
                href={process.env.REACT_APP_LINKEDIN_URL}
                style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
                rel="nofollow noreferrer"
                target="_blank"
              >
                <ReactSVG src={linkedinIcon} />
              </a>
            </div>
          </div>
          <div className={styles.footerItemsWithInput}>
            <div className={styles.footerItems}>
              {LINKS.filter((link) => !!link.name).map((link, index) => {
                if (link.isExternal) {
                  return (
                    <a
                      key={`${link.link}-${index}`}
                      href={link.link}
                      className={styles.footerLink}
                      style={{
                        color: process.env.REACT_APP_PRIMARY_FONT_COLOR,
                      }}
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      {link.name}
                    </a>
                  );
                }

                return (
                  <Link
                    key={`${link.link}-${index}`}
                    to={link.link ?? ''}
                    className={styles.footerLink}
                    style={{
                      color: process.env.REACT_APP_PRIMARY_FONT_COLOR,
                    }}
                  >
                    {link.name}
                  </Link>
                );
              })}
            </div>
            <EmailInput />
          </div>
        </div>
        <div className={styles.copyrightContainer}>
          <Link
            to={routes.privacyPolicy}
            className={styles.copyrightItem}
            style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
          >
            {process.env.REACT_APP_FOOTER_RULE_TEXT}
          </Link>
          <div
            className={styles.copyrightItem}
            style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
          >
            {process.env.REACT_APP_FOOTER_COPYRIGHT_TEXT} •{' '}
            <a
              href="https://ababa.tech"
              rel="nofollow noreferrer"
              target="_blank"
              style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
            >
              ababa.tech
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
