import React from 'react';
import styles from './Navigation.module.scss';
import { IconButton } from '@mui/material';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { Link, NavLink } from 'react-router-dom';
import { NavigationItem } from '../Layout/Layout';

export type Props = {
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  navigationItems: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

export const Navigation = ({
  onDrawerClick,
  isMobileMenuOpen,
  navigationItems,
}: Props) => {
  const { width } = useWindowSize();

  return (
    <>
      <header
        className={styles.navigationContainer}
        style={{ backgroundColor: process.env.REACT_APP_THIRD_COLOR }}
      >
        <div className={styles.siteName}>
          <Link
            to={''}
            style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
          >
            {process.env.REACT_APP_HEADER_SITE_NAME}
          </Link>
        </div>
        <div className={styles.navigationItems}>
          {width && width < MOBILE_BREAK_POINT ? (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
                size="sm"
              />
            </IconButton>
          ) : (
            navigationItems.map((navigationItem, index) => {
              if (navigationItem.isExternal) {
                return (
                  <a
                    key={navigationItem.label}
                    href={navigationItem.to}
                    className={styles.navigationItem}
                    style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    {navigationItem.label}
                  </a>
                );
              }

              return (
                <NavLink
                  key={`${navigationItem.label}-${index}`}
                  to={navigationItem.to}
                  className={styles.navigationItem}
                  style={{ color: process.env.REACT_APP_PRIMARY_FONT_COLOR }}
                >
                  {navigationItem.label}
                </NavLink>
              );
            })
          )}
        </div>
      </header>
    </>
  );
};

export default Navigation;
