import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Loader from '../../common/Loader/Loader';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';

const Homepage = React.lazy(() => import('../../pages/Homepage/Homepage'));
const CoursesPage = React.lazy(
  () => import('../../pages/CoursesPage/CoursesPage'),
);
const ContactsPage = React.lazy(
  () => import('../../pages/ContactsPage/ContactsPage'),
);
const RegistrationPage = React.lazy(
  () => import('../../pages/RegistrationPage/RegistrationPage'),
);
const ConsultationPage = React.lazy(
  () => import('../../pages/ConsultationPage/ConsultationPage'),
);
const BusinessPage = React.lazy(
  () => import('../../pages/BusinessPage/BusinessPage'),
);
const FinancePage = React.lazy(
  () => import('../../pages/FinancePage/FinancePage'),
);
const PrivacyPolicyPage = React.lazy(
  () => import('../../pages/PrivacyPolicyPage/PrivacyPolicyPage'),
);
const ArticlePage = React.lazy(
  () => import('../../pages/ArticlePage/ArticlePage'),
);

export const Router = () => {
  return (
    <BrowserRouter basename="/">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <Suspense fallback={<Loader isLoading isFullScreen />}>
          <Layout>
            <Routes>
              <Route path={routes.courses} element={<CoursesPage />} />
              <Route path={routes.business} element={<BusinessPage />} />
              <Route path={routes.finance} element={<FinancePage />} />
              <Route path={routes.article} element={<ArticlePage />} />
              <Route
                path={routes.privacyPolicy}
                element={<PrivacyPolicyPage />}
              />
              <Route path={routes.contacts} element={<ContactsPage />} />
              <Route
                path={routes.registration}
                element={<RegistrationPage />}
              />
              <Route
                path={routes.consultation}
                element={<ConsultationPage />}
              />
              <Route path={routes.homepage} element={<Homepage />} />
              <Route path="*" element={<Navigate to={routes.homepage} />} />
            </Routes>
          </Layout>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Router;
