import React, { useEffect, useMemo } from 'react';
import styles from './ErrorFallback.module.scss';
import {
  faTriangleExclamation,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

const NEW_VERSION_ERRORS = [
  // eslint-disable-next-line max-len
  /Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node./,
  /Loading chunk [\d]+ failed/,
];

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  const isNewVersionError = useMemo(
    () =>
      NEW_VERSION_ERRORS.find((newVersionError) =>
        newVersionError.test(error.toString()),
      ),
    [error],
  );

  useEffect(() => {
    if (!isNewVersionError) {
      return;
    }

    setTimeout(() => {
      resetErrorBoundary();
    }, 3000);
  }, [isNewVersionError]);

  const getMessage = () => {
    if (isNewVersionError) {
      return 'Yra nauja svetainės versija! Po 3 sekundžių būsite nukreipti...';
    }

    return 'Įvyko nežinoma klaida. Pabandykite dar kartą.';
  };

  return (
    <div className={styles.errorFallback}>
      <FontAwesomeIcon
        className={cx(styles.icon, { [styles.errorIcon]: !isNewVersionError })}
        icon={isNewVersionError ? faCircleExclamation : faTriangleExclamation}
        size="4x"
      />
      <div className={styles.errorMessage}>{getMessage()}</div>
      <div className={styles.refresh} onClick={() => resetErrorBoundary()}>
        Spustelėkite čia, kad atnaujintumėte
      </div>
    </div>
  );
};

export default ErrorFallback;
