import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import styles from './Loader.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Props = {
  isLoading: boolean;
  isFullScreen?: boolean;
};

const Loader = ({ isLoading, isFullScreen }: Props) => (
  <div
    className={cx(styles.loader, {
      [styles.fullScreen]: isFullScreen,
    })}
  >
    {isLoading && (
      <div className={styles.loaderIcon}>
        <FontAwesomeIcon icon={faCircleNotch as IconProp} spin size="lg" />
      </div>
    )}
  </div>
);

export default Loader;
